<template>
  <Menu as="div" class="relative inline-block">
    <div>
      <MenuButton
        v-if="buttonType == 'Icon'"
        class="inline-flex w-full justify-center hover:bg-gray-200 rounded-xl pa-2"
        :style="'z-index: 9999 !important'"
        @click.stop="openMenu"
      >
        <Icon class="size-6 text-primary" icon="mdi-dots-vertical" />
      </MenuButton>
      <MenuButton
        v-else
        class="ring-1 py-1.5 px-3 flex ml-3 rounded ring-primary text-primary cursor-pointer z-1"
      >
        <span class="text-sm">{{ buttonText }}</span>
        <Icon class="size-6 text-primary" icon="mdi-chevron-down" />
      </MenuButton>
    </div>
    <MenuItems
      :class="$attrs?.menuItemsClass"
      :style="$route?.name == 'collection.list' && 'z-index: 9999 !important'"
      class="absolute mt-2 w-32 z-1 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
    >
      <div class="px-1 py-1">
        <template v-for="(action, index) in actions" :key="index">
          <MenuItem v-if="action?.show" v-slot="{ active }">
            <button
              @click="$emit('menuAction', action, actionData)"
              :class="[
                active && 'bg-fuchsia-50',
                'group flex w-full items-center rounded-md pa-3 text-sm text-dark  font-bold text-nowrap '
              ]"
            >
              <div class="hover:text-primary flex">
                <Icon v-if="action?.icon" class="size-5 mr-1" :icon="action?.icon"></Icon>
                {{ action?.name }}
              </div>
            </button>
          </MenuItem>
        </template>
      </div>
    </MenuItems>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
export default {
  name: 'ActionMenu',

  props: {
    actions: {
      type: Array,
      required: true
    },
    actionData: { type: Object, default: {} },
    buttonType: { type: String, default: 'Icon' },
    buttonText: { type: String, default: '' }
  },

  emits: ['menuAction'],

  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem
  },

  methods: {
    openMenu(event) {
      const parentCard = event.target.closest('.item-card')
      if (parentCard && parentCard.classList.contains('selected')) {
        parentCard.classList.remove('selected')
      }
    }
  }
}
</script>
