<template>
  <div class="px-3 border-b">
    <div
      @click=";(openAccountMenu = !openAccountMenu), $emit('openMenu')"
      class="w-full flex items-center justify-between py-3 hover:bg-gray-100 cursor-pointer"
    >
      <div class="flex">
        <UserAvatar :user="$account || $user" :color="'primary'" />
        <div class="px-2 pl-3">
          <span class="flex text-base">{{ $account?.name }}</span>
          <span class="text-sm text-stone-500">{{ getRoleName($account) }}</span>
        </div>
      </div>
      <button class="pa-2 rounded-full hover:bg-gray-200">
        <Icon class="size-6" :icon="openAccountMenu ? 'mdi-chevron-up' : 'mdi-chevron-down'"></Icon>
      </button>
    </div>
    <div v-if="openAccountMenu">
      <div v-for="(account, index) in accounts" :key="index" @click="onChangeAccount(account)">
        <div class="flex items-center py-3 hover:bg-gray-100 cursor-pointer">
          <UserAvatar :user="account" :color="index / 2 == 0 ? 'info' : 'primary'" />
          <div class="px-2 pl-3">
            <span class="flex text-base">{{ account?.name }}</span>
            <span class="text-sm text-stone-500">{{ getRoleName(account) }}</span>
          </div>
        </div>
      </div>
      <div
        class="py-4 px-4 flex items-center hover:bg-gray-100 cursor-pointer"
        @click="createNewWorkspace()"
      >
        <Icon class="size-6" icon="mdi-plus"></Icon>
        <span class="text-sm pl-4">Create Workspace</span>
      </div>
    </div>
  </div>
</template>

<script>
import find from 'lodash/find'
import UserAvatar from '@components/UserAvatar.vue'
export default {
  name: 'AccountsMenu',
  props: {
    menu: { type: Boolean, default: true }
  },

  components: {
    UserAvatar
  },

  data() {
    return {
      openAccountMenu: false
    }
  },

  computed: {
    accounts() {
      return this.$user?.accounts?.filter((account) => {
        return account._id != this.$account?._id
      })
    }
  },

  methods: {
    createNewWorkspace() {
      this.$openBlankWindow({
        name: 'onboarding-profile',
        query: { createWorkspace: true }
      })
    },
    onChangeAccount(account) {
      this.switchAccount(account)
      this.$emit('closeMenu')
    },
    getRoleName(account) {
      let roleName = ''
      const accountData = find(account?.users, { user: this.$user._id })
      if (accountData) {
        roleName = accountData.role.name
      }
      return roleName
    }
  }
}
</script>
